<template>
  <b-button
    v-if="token"
    variant="danger"
    class="text-center"
    target="_blank"
    :href="'https://destek.code64.com.tr/AppLogin/' + token"
  >
    <template v-if="textStatus">
      <FeatherIcon
        icon="LifeBuoyIcon"
      />
      Geri Bildirim
    </template>
    <template v-else>
      <FeatherIcon
        icon="LifeBuoyIcon"
      />
    </template>
  </b-button>
</template>
<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'Support',
  components: {
    BButton,
  },
  props: {
    textStatus: {
      type: Boolean,
      default: true,
    },
    token: {
      type: String,
      default: null,
    },
  },
}
</script>
