export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Müşteri Kartları',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Ekle',
        route: 'CustomersAdd',
        resource: 'CUSTOMERS_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Customers',
        resource: 'CUSTOMERS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Görüşme Notları',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'InterviewCreate',
        resource: 'INTERVIEWS_ADD',
        action: 'read',
      },
      {
        title: 'Sıfır Satış',
        route: 'SalesInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Servis Mekanik',
        route: 'ServiceMechanicalInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Servis Hasar',
        route: 'ServiceDamageInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'İkinci El',
        route: 'SecondHandInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Sigorta',
        route: 'InsuranceInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Filo Kiralama',
        route: 'RentalInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Gayrimenkul',
        route: 'RealtySaleInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Müşteri İlişkileri',
        route: 'CRInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Yedek Parça',
        route: 'SparePartsInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Aksesuar Satış',
        route: 'AccessorySalesInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Araç Kartları',
    icon: 'DiscIcon',
    children: [
      {
        title: 'Müşteri Araç',
        route: 'Cars',
        resource: 'CARS_LIST',
        action: 'read',
      },
      {
        title: 'Ekspertiz Araç',
        route: 'Swapcars',
        resource: 'SWAPCARS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Sıfır Satış',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Satış Föyleri',
        route: 'SaleOrderForms',
        resource: 'SALE_ORDER_LIST',
        action: 'read',
      },
      {
        title: 'Stoklar',
        route: 'SaleStocks',
        resource: 'SALE_STOCK_LIST',
        action: 'read',
      },
      {
        title: 'GPR Kapanış Veri Ekle',
        route: 'SaleGoals',
        resource: 'SALE_GOALS_LIST',
        action: 'read',
      },
      {
        title: 'Hedefler',
        route: 'ConsultantGoals',
        resource: 'SALE_CONSULANT_GOALS_LIST',
        action: 'read',
      },
      {
        title: 'Teslimat Bekleyen Araçlar',
        route: 'SaleDeliveries',
        resource: 'SALE_DELIVERY_LIST',
        action: 'read',
      },
      {
        title: 'Teslim Edilen Araçlar',
        route: 'SaleDeliveriesCompleted',
        resource: 'SALE_DELIVERED_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'İkinci El Satış',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Satış Föyleri',
        route: 'I2Sales',
        resource: 'I2_SALE_ORDER_LIST',
        action: 'read',
      },
      {
        title: 'Stoklar',
        route: '#',
        resource: 'I2_SALE_STOCK_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Filo Kiralama',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Araç Kartları',
        route: 'RentalStocks',
      },
      {
        title: 'Sözleşmeler',
        route: 'RentalContracts',
      },
      {
        title: 'Kasko Kontrol',
        route: null,
      },
      {
        title: 'Sözleşme Takip',
        route: null,
      },
      {
        title: 'Sigorta Takip',
        route: null,
      },
      {
        title: 'Noter Satış',
        route: null,
      },
      {
        title: 'Damga Vergisi',
        route: null,
      },
    ],
  },
  {
    title: 'Gayrimenkul',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Satışlar',
        route: 'RealtySales',
        resource: 'REALTY_SALES',
        action: 'read',
      },
      {
        title: 'Stoklar',
        route: 'RealtyStocks',
        resource: 'REALTY_STOCKS',
        action: 'read',
      },
      {
        title: 'Tanımlamalar',
        children: [
          {
            title: 'Projeler',
            route: 'RealtyProjects',
            resource: 'REALTY_DEFINES_PROJECTS',
            action: 'read',
          },
          {
            title: 'Gayrimenkul Tipi',
            route: 'RealtyTypes',
            resource: 'REALTY_DEFINES_TYPES',
            action: 'read',
          },
          {
            title: 'Bloklar',
            route: 'RealtyBlocks',
            resource: 'REALTY_DEFINES_BLOCKS',
            action: 'read',
          },

        ],
      },
    ],
  },
  {
    title: 'Müşteri İlişkileri',
    icon: 'PhoneCallIcon',
    children: [
      {
        title: 'Başvuru Ekle',
        route: 'CrmSearch',
        resource: 'CRM_REQUESTS_ADD',
        action: 'read',
      },
      {
        title: 'Bekleyen',
        route: 'CrmPending',
        resource: 'CRM_REQUESTS_LIST',
        action: 'read',
      },
      {
        title: 'Takip Edilen',
        route: 'CrmTracking',
        resource: 'CRM_REQUESTS_LIST',
        action: 'read',
      },
      {
        title: 'Onay Bekleyen',
        route: 'CrmApproval',
        resource: 'CRM_REQUESTS_LIST',
        action: 'read',
      },
      {
        title: 'Tümü',
        route: 'CrmAll',
        resource: 'CRM_REQUESTS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Servis',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Randevular',
        route: 'Service',
        resource: 'SERVICES_LIST',
        action: 'read',
      },
      {
        title: 'İndirim Kodu Doğrulama',
        route: null,
        resource: 'SERVICES_LIST',
        action: 'read',
      },
      {
        title: 'Müşteri SMS Onay',
        route: null,
        resource: 'SERVICES_SMS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Yedek Parça',
    icon: 'GridIcon',
    children: [
      {
        title: 'Bekleyen Siparişler',
        route: 'SparePartsWaiting',
        resource: 'SPAREPART_ORDERS_LIST',
        action: 'read',
      },
      {
        title: 'Tüm Siparişler',
        route: 'SpareParts',
        resource: 'SPAREPART_ORDERS_LIST',
        action: 'read',
      },
      /* {
        title: 'Lastik Oteli',
        route: null,
        resource: 'SPAREPART_TIRE_HOTEL_LIST',
        action: 'read',
      }, */
    ],
  },
  {
    title: 'Sigorta',
    icon: 'LifeBuoyIcon',
    children: [
      {
        title: 'Yenileme Listesi',
        children: [
          {
            title: 'Liste',
            route: 'InsuranceRenewals',
            resource: 'INSURANCE_RENEWAL_LIST',
            action: 'read',
          },
          {
            title: 'Dosya Aktarımı',
            route: 'InsuranceRenewalImport',
            resource: 'INSURANCE_RENEWAL_IMPORT',
            action: 'read',
          },
          {
            title: 'Dönemler',
            route: 'InsuranceRenewalPeridos',
            resource: 'INSURANCE_RENEWAL_IMPORT',
            action: 'read',
          },
        ],
        resource: 'INSURANCE_PUBLIC',
        action: 'read',
      },
      {
        title: 'Toplu Görüşme Notu',
        route: null,
        resource: 'INSURANCE_INTERVIEW_BATCH',
        action: 'read',
      },
    ],
  },
  /* {
    title: 'Satın Alma',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'Siparişler',
        route: null,
        children: [
          {
            title: 'Sipariş Listesi',
            route: 'purchasing_orders',
            resource: 'PURCHASING_ORDERS_LIST',
            action: 'read',
          },
          {
            title: 'Bekleyen Siparişler',
            route: 'WaitingOrders',
            resource: 'PURCHASING_ORDERS_LIST',
            action: 'read',
          },
          {
            title: 'Tamamlanan Siparişler',
            route: 'CompletedOrders',
            resource: 'PURCHASING_ORDERS_LIST',
            action: 'read',
          },
          {
            title: 'İptal Siparişler',
            route: 'CanceledOrders',
            resource: 'PURCHASING_ORDERS_LIST',
            action: 'read',
          },
        ],
      },
      {
        title: 'Talepler',
        route: null,
        children: [
          {
            title: 'Talep Listesi',
            route: 'purchasing_requests',
            resource: 'PURCHASING_REQUESTS_LIST',
            action: 'read',
          },
          {
            title: 'Bekleyen Talepler',
            route: 'WaitingPurchasingRequests',
            resource: 'PURCHASING_REQUESTS_LIST',
            action: 'read',
          },
          {
            title: 'İptal Talepler',
            route: 'CanceledPurchasingRequests',
            resource: 'PURCHASING_REQUESTS_LIST',
            action: 'read',
          },
          {
            title: 'Tamamlanan Talepler',
            route: 'CompletedPurchasingRequests',
            resource: 'PURCHASING_REQUESTS_LIST',
            action: 'read',
          },
        ],
      },
      {
        title: 'Tanımlamalar',
        children: [
          {
            title: 'Ürün Tipleri',
            route: 'product_types',
            resource: 'PURCHASING_DEFINES',
            action: 'read',
          },
          {
            title: 'Ürün Grupları',
            route: 'product_groups',
            resource: 'PURCHASING_DEFINES',
            action: 'read',
          },
          {
            title: 'Miktar Tipleri',
            route: 'product_units',
            resource: 'PURCHASING_DEFINES',
            action: 'read',
          },
          {
            title: 'Tedarikçiler',
            route: 'suppliers',
            resource: 'PURCHASING_DEFINES',
            action: 'read',
          },
          {
            title: 'Satın Alma Durumları',
            route: 'purchasing_statuses',
            resource: 'PURCHASING_DEFINES',
            action: 'read',
          },
        ],
      },
    ],
  }, */
  {
    title: 'Pazarlama',
    icon: 'GlobeIcon',
    children: [
      {
        title: 'Dijital',
        children: [
          {
            title: 'Reklamlar',
            route: 'MarketingAds',
            resource: 'MARKETING_ADS_LIST',
            action: 'read',
          },
          {
            title: 'Reklam Formları',
            route: 'MarketingAdForms',
            resource: 'MARKETING_ADFORMS_LIST',
            action: 'read',
          },
          {
            title: 'Reklam Tipleri',
            route: 'MarketingAdTypes',
            resource: 'MARKETING_ADTYPES_LIST',
            action: 'read',
          },
          {
            title: 'Reklam Platformları',
            route: 'MarketingAdPlatforms',
            resource: 'MARKETING_ADPLATFORMS_LIST',
            action: 'read',
          },
        ],
        resource: 'MARKETING_DIGIAL',
        action: 'read',
      },
      {
        title: 'Doğum Günü SMS',
        route: 'MarketingBirthdaySms',
      },
      {
        title: 'Data Aktarım',
        route: null,
      },
      // {
      //   title: 'Aktiviteler',
      //   route: null,
      // },
    ],
  },
  {
    title: 'İnsan Kaynakları',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Duyurular',
        route: 'HrNotifications',
        resource: 'HR_NOTIFICATIONS_LIST',
        action: 'read',
      },
      {
        title: 'Personeller',
        route: 'HrEmployees',
        resource: 'HR_EMPLOYEES_LIST',
        action: 'read',
      },
      {
        title: 'Portal',
        children: [
          {
            title: 'Duyurular',
            route: 'PortalNotices',
            resource: 'PORTAL_NOTICES_LIST',
            action: 'read',
          },
          {
            title: 'Günün Sözleri',
            route: 'PortalQuoteOfDays',
            resource: 'PORTAL_QUOTE_OF_DAYS_LIST',
            action: 'read',
          },
          {
            title: 'Tek Soruluk Anketler',
            route: 'PortalPolls',
            resource: 'PORTAL_POLLS_LIST',
            action: 'read',
          },
          {
            title: 'Yemek Menüsü',
            route: 'PortalMeals',
            resource: 'PORTAL_MEALS_LIST',
            action: 'read',
          },
          {
            title: 'Etkinlik Takvimi',
            route: 'PortalEvents',
            resource: 'PORTAL_EVENTS_LIST',
            action: 'read',
          },
          {
            title: 'Ödüller',
            route: 'PortalAwards',
            resource: 'PORTAL_AWARDS_LIST',
            action: 'read',
          },
          {
            title: 'Ayın Röportajı',
            route: 'PortalReportages',
            resource: 'PORTAL_REPORTAGES_LIST',
            action: 'read',
          },
          /*
          {
            title: 'Aylık Bülten',
            route: '',
            resource: 'PORTAL_AWARDS_LIST',
            action: 'read',
          }, */
          {
            title: 'Fotoğraf Galerisi',
            route: 'PortalGallery',
            resource: 'PORTAL_GALLERY_LIST',
            action: 'read',
          },
          {
            title: 'Personel İlanları',
            route: 'PortalCareers',
            resource: 'PORTAL_CAREERS_LIST',
            action: 'read',
          },
          {
            title: 'Eğitim Takvimi',
            route: 'PortalEduCalendars',
            resource: 'PORTAL_CAREERS_LIST',
            action: 'read',
          },
          {
            title: 'Geçmiş Eğitimler',
            route: 'PortalEducations',
            resource: 'PORTAL_CAREERS_LIST',
            action: 'read',
          },
          {
            title: 'Genel Eğitimler',
            route: 'PortalFixedEducations',
            resource: 'PORTAL_CAREERS_LIST',
            action: 'read',
          },
          {
            title: 'Doküman Arşivi',
            route: 'PortalDocuments',
            resource: 'PORTAL_CAREERS_LIST',
            action: 'read',
          },
          {
            title: 'Anlaşmalı Kuruluşlar',
            route: 'PortalContractedCompanies',
            resource: 'PORTAL_CONTRACTED_COMPANIES_LIST',
            action: 'read',
          },
        ],
      },
    ],
  },
  {
    title: 'Raporlar',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Sıfır Satış',
        children: [
          {
            title: 'GPR',
            route: 'ReportsSalesGPR',
            resource: 'REPORTS_SALES_GPR',
            action: 'read',
          },
          {
            title: 'Yönetim GPR',
            route: null,
          },
          {
            title: 'Temsilciler',
            route: 'ReportsSaleUsers',
            resource: 'REPORTS_SALE_USERS',
            action: 'read',
          },
          {
            title: 'Danışman Karnesi',
            route: null,
          },
          {
            title: 'Dijital',
            route: 'DigitalReport',
            resource: 'REPORTS_SALE_DIGITAL',
            action: 'read',
          },
          {
            title: 'Stoklar',
            route: 'SalesStockReport',
            resource: 'REPORTS_SALE_STOCKS',
            action: 'read',
          },
          {
            title: 'Görüşmeler',
            route: 'ReportsSaleInterviews',
            resource: 'REPORTS_SALE_INTERVIEWS',
            action: 'read',
          },
          {
            title: 'Aksesuar Detayı',
            route: 'ReportsSaleAccessories',
            resource: 'REPORTS_SALE_ACCESSORIES',
            action: 'read',
          },
          {
            title: 'Dijital Dönüş Süreleri',
            route: 'DigitalResponseReport',
            resource: 'REPORTS_SALE_DIGITAL_RESPONSE',
            action: 'read',
          },
          {
            title: 'İl / İlçe Satışlar',
            route: 'SalesByCity',
            resource: 'REPORTS_SALE_BY_CITY',
            action: 'read',
          },
        ],
        resource: 'REPORTS_SALE',
        action: 'read',
      },
      {
        title: 'İkinci El Satış',
        children: [
          {
            title: 'Dijital',
            route: null,
          },
        ],
      },
      {
        title: 'Servis',
        children: [
          {
            title: 'Randevular',
            route: null,
          },
          {
            title: 'BCS Sms',
            route: 'ReportsBcsSms',
            resource: 'REPORTS_SERVICE_BCS_SMS',
            action: 'read',
          },
          {
            title: 'Doğum Günü Sms',
            route: 'ReportsBirthdaySms',
            resource: 'REPORTS_SERVICE_BIRTHDAY_SMS',
            action: 'read',
          },
        ],
        resource: 'REPORTS_SERVICE',
        action: 'read',
      },
      {
        title: 'Filo Kiralama',
        children: [
          {
            title: 'Görüşmeler',
            route: 'ReportsRentalInterivews',
            resource: 'REPORTS_RENTAL_INTERVIEWS',
            action: 'read',
          },
          {
            title: 'Yenileme',
            route: 'ReportsRentalRenewals',
            resource: 'REPORTS_RENTAL_RENEWALS',
            action: 'read',
          },
        ],
        resource: 'REPORTS_RENTAL',
        action: 'read',
      },
      {
        title: 'Müşteri İlişkileri',
        children: [
          {
            title: 'Başvurular',
            route: 'ReportsCrmRequests',
            resource: 'REPORTS_CRM_REQUESTS',
            action: 'read',
          },
          {
            title: 'Başvuru Dönüş Süresi',
            route: 'ReportsCrmRequestDurations',
            resource: 'REPORTS_CRM_REQUEST_DURATIONS',
            action: 'read',
          },
          {
            title: 'PBGA',
            route: null,
          },
          {
            title: 'Anketler',
            route: 'ReportsCrmSurveys',
            resource: 'REPORTS_CRM_SURVEYS',
            action: 'read',
          },
        ],
        resource: 'REPORTS_CRM',
        action: 'read',
      },
      {
        title: 'Sigorta',
        children: [
          {
            title: 'Üretim',
            route: 'ReportsInsuranceProduction',
            resource: 'REPORTS_INSURANCE_PRODUCTION',
            action: 'read',
          },
          {
            title: 'Üretim Firma',
            route: null,
          },
          {
            title: 'Sıfır Satış',
            route: null,
          },
          {
            title: 'İkinci El Satış',
            route: null,
          },
          {
            title: 'Yenileme',
            route: null,
          },
        ],
        resource: 'REPORTS_INSURANCE',
        action: 'read',
      },
      {
        title: 'Pazarlama',
        children: [
          {
            title: 'Dijital Reklam',
            route: 'ReportsMarketingAds',
            resource: 'REPORTS_MARKETING_DIGITAL',
            action: 'read',
          },
          {
            title: 'Otomotiv',
            route: 'ReportsMarketingAutomotive',
            resource: 'REPORTS_MARKETING_AUTOMOTIVE',
            action: 'read',
          },
        ],
        resource: 'REPORTS_MARKETING',
        action: 'read',
      },
      {
        title: 'CSI',
        children: [
          {
            title: 'Marka',
            route: 'ReportsCsiBrands',
            resource: 'REPORTS_CSI_BRANDS',
            action: 'read',
          },
          {
            title: 'Danışman',
            route: 'ReportsCsiUsers',
            resource: 'REPORTS_CSI_USERS',
            action: 'read',
          },
        ],
        resource: 'REPORTS_CSI',
        action: 'read',
      },
      {
        title: 'Veri Girişi',
        children: [
          {
            title: 'Servis Giriş Adetleri',
            route: 'ServiceCounters',
            resource: 'EXTERNAL_SERVICE_COUNTERS',
            action: 'read',
          },
          {
            title: 'CSI Marka',
            route: 'CsiBrands',
            resource: 'EXTERNAL_CSI',
            action: 'read',
          },
          {
            title: 'CSI Danışman',
            route: 'CsiUsers',
            resource: 'EXTERNAL_CSI',
            action: 'read',
          },
        ],
        resource: 'EXTERNAL_DATA',
        action: 'read',
      },
    ],
  },
  {
    title: 'Tanımlamalar',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Araç Markaları',
        route: 'Swapbrands',
        resource: 'DEFINES_SWAP_BRANDS',
        action: 'read',
      },
      {
        title: 'Gönye Otomotiv Markaları',
        route: 'Brands',
        resource: 'DEFINES_BRANDS',
        action: 'read',
      },
      {
        title: 'Yakıt Tipleri',
        route: 'Fuels',
        resource: 'DEFINES_FUELS',
        action: 'read',
      },
      {
        title: 'Vites Tipleri',
        route: 'Gears',
        resource: 'DEFINES_GEARS',
        action: 'read',
      },
      {
        title: 'Renk Seçenekleri',
        route: 'Colors',
        resource: 'DEFINES_COLORS',
        action: 'read',
      },
      /*
      {
        title: 'Lastik Markaları',
        route: 'TireBrands',
        resource: 'DEFINES_TIRE_BRANDS',
        action: 'read',
      },
      {
        title: 'Lastik Boyutları',
        route: 'TireSizes',
        resource: 'DEFINES_TIRE_SIZES',
        action: 'read',
      },
      {
        title: 'Lastik Durumları',
        route: 'TireStatuses',
        resource: 'DEFINES_TIRE_STATUSES',
        action: 'read',
      }, */
      {
        title: 'Lastik Tipleri',
        route: 'TireTypes',
        resource: 'DEFINES_TIRE_TYPES',
        action: 'read',
      },
      {
        title: 'Raflar',
        route: 'TireShelfs',
        resource: 'DEFINES_TIRE_SHELFS',
        action: 'read',
      },
      {
        title: 'Başvuru Konuları',
        route: 'CrmSubjects',
        resource: 'DEFINES_CRM_SUBJECTS',
        action: 'read',
      },
      {
        title: 'Başvuru Tipleri',
        route: 'CrmFeedbackTypes',
        resource: 'DEFINES_CRM_TYPES',
        action: 'read',
      },
      {
        title: 'Başvuru Durumları',
        route: 'CrmStatuses',
        resource: 'DEFINES_CRM_STATUSES',
        action: 'read',
      },
      {
        title: 'Başvuru Kaynakları',
        route: 'CrmApplySources',
        resource: 'DEFINES_CRM_APPLY_SOURCES',
        action: 'read',
      },
      {
        title: 'Önem Dereceleri',
        route: 'CrmLevels',
        resource: 'DEFINES_CRM_LEVELS',
        action: 'read',
      },
      {
        title: 'Sigorta Firmaları',
        route: 'InsuranceCompanies',
        resource: 'DEFINES_INSURANCE_COMPANIES',
        action: 'read',
      },
      {
        title: 'Şehir',
        route: 'Cities',
        resource: 'DEFINES_CITIES',
        action: 'read',
      },
      {
        title: 'İlçe',
        route: 'Districts',
        resource: 'DEFINES_DISTRICTS',
        action: 'read',
      },
      {
        title: 'Temas',
        route: 'Meets',
        resource: 'DEFINES_MEETS',
        action: 'read',
      },
      {
        title: 'Temas Grupları',
        route: 'MeetGroups',
        resource: 'DEFINES_MEET_GROUPS',
        action: 'read',
      },
      {
        title: 'Görüşme Tipleri',
        route: 'InterviewTypes',
        resource: 'DEFINES_INTERVIEW_TYPES',
        action: 'read',
      },
      {
        title: 'Servis Tipleri',
        route: 'ServiceTypes',
        resource: 'DEFINES_SERVICE_TYPES',
        action: 'read',
      },
      {
        title: 'Servis Durumları',
        route: 'ServiceStatuses',
        resource: 'DEFINES_SERVICE_STATUSES',
        action: 'read',
      },
      {
        title: 'Servis SMS Tipleri',
        route: 'ServiceSmsTypes',
        resource: 'DEFINES_SERVICE_SMS_TYPES',
        action: 'read',
      },
      {
        title: 'Departmanlar',
        route: 'Departments',
        resource: 'DEFINES_DEPARTMENTS',
        action: 'read',
      },
      {
        title: 'Meslekler',
        route: 'Jobs',
        resource: 'DEFINES_JOBS',
        action: 'read',
      },
      {
        title: 'Sektörler',
        route: 'Sectors',
        resource: 'DEFINES_SECTORS',
        action: 'read',
      },
      {
        title: 'Lokasyon',
        route: 'Locations',
        resource: 'DEFINES_LOCATIONS',
        action: 'read',
      },
      {
        title: 'Ödeme Tipleri',
        route: 'Payments',
        resource: 'DEFINES_PAYMENT_TYPES',
        action: 'read',
      },
      {
        title: 'KDV Oranları',
        route: 'KdvRates',
        resource: 'DEFINES_KDV_RATES',
        action: 'read',
      },
      {
        title: 'ÖTV Oranları',
        route: 'OtvRates',
        resource: 'DEFINES_OTV_RATES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Sistem',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'Users',
        resource: 'USERS_LIST',
        action: 'read',
      },
      {
        title: 'Kullanıcı Tipleri',
        route: 'UserTypes',
        resource: 'USER_TYPES_LIST',
        action: 'read',
      },
      {
        title: 'SMTP Ayaları',
        route: 'configs/smtp',
      },
      /*
      {
        title: 'Sistem Geri Bildirim',
        route: 'configs/feedbacks',
      },
         */
    ],
  },
]
