export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Müşteri Kartları',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Ekle',
        route: 'CustomersAdd',
        resource: 'CUSTOMERS_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Customers',
        resource: 'CUSTOMERS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Görüşme Notları',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'InterviewCreate',
        resource: 'INTERVIEWS_ADD',
        action: 'read',
      },
      {
        title: 'Sıfır Satış',
        route: 'SalesInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Servis Mekanik',
        route: 'ServiceMechanicalInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Servis Hasar',
        route: 'ServiceDamageInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'İkinci El',
        route: 'SecondHandInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Sigorta',
        route: 'InsuranceInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Müşteri İlişkileri',
        route: 'CRInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Yedek Parça',
        route: 'SparePartsInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
      {
        title: 'Aksesuar Satış',
        route: 'AccessorySalesInterviewOpen',
        resource: 'INTERVIEWS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Araç Kartları',
    icon: 'DiscIcon',
    children: [
      {
        title: 'Müşteri Araç',
        route: 'Cars',
        resource: 'CARS_LIST',
        action: 'read',
      },
      {
        title: 'Ekspertiz Araç',
        route: 'Swapcars',
        resource: 'SWAPCARS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Sıfır Satış',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Satış Föyleri',
        route: 'SaleOrderForms',
        resource: 'SALE_ORDER_FORMS_LIST',
        action: 'read',
      },
      {
        title: 'Satış Evrakları',
        route: 'SaleDocuments',
        resource: 'SALE_DOCUMENTS',
        action: 'read',
      },
      {
        title: 'Stoklar',
        route: 'SaleStocks',
        resource: 'SALE_STOCK_LIST',
        action: 'read',
      },
      {
        title: 'GPR Kapanış Veri Ekle',
        route: 'SaleGoals',
        resource: 'SALE_GOALS_LIST',
        action: 'read',
      },
      {
        title: 'Hedefler',
        route: 'ConsultantGoals',
        resource: 'CONSULTANT_GOALS_LIST',
        action: 'read',
      },
      {
        title: 'Teslimat Bekleyen Araçlar',
        route: 'SaleDeliveries',
        resource: 'SALE_DELIVERIES_LIST',
        action: 'read',
      },
      {
        title: 'Teslim Edilen Araçlar',
        route: 'SaleDeliveriesCompleted',
        resource: 'COMPLETED_SALE_DELIVERIES_LIST',
        action: 'read',
      },
      {
        title: 'Föy İptal İstekleri',
        route: 'PendingCancelSale',
        resource: 'PENDING_CANCEL_SALE_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'İkinci El Satış',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Satış Föyleri',
        route: 'G2Sales',
        resource: 'G2_SALE_ORDER_LIST',
        action: 'read',
      },
      /* {
        title: 'Stoklar',
        route: '#',
        resource: 'G2_SALE_STOCK_LIST',
        action: 'read',
      }, */
    ],
  },
  {
    title: 'Teklifler',
    route: 'Offers',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'OfferCustomerSearch',
        resource: 'OFFER_ADD',
        action: 'read',
      },
      {
        title: 'Liste',
        route: 'Offers',
        resource: 'OFFER_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Web Talepleri',
    icon: 'CompassIcon',
    children: [
      {
        title: 'Biz Sizi Arayalım',
        route: 'Callform',
        resource: 'WEBFORM_CALLFORM',
        action: 'read',
      },
      {
        title: 'Test Sürüşü',
        route: 'Testdrive',
        resource: 'WEBFORM_TESTDRIVE',
        action: 'read',
      },
      {
        title: 'Gönye2 Teklif Ver',
        route: 'G2bidform',
        resource: 'WEBFORM_G2BIDFORM',
        action: 'read',
      },
      {
        title: 'Gönye2 Almak İstiyorum',
        route: 'G2buyform',
        resource: 'WEBFORM_G2BUYFORM',
        action: 'read',
      },
      {
        title: 'Gönye2 Satmak İstiyorum',
        route: 'G2saleform',
        resource: 'WEBFORM_G2SALEFORM',
        action: 'read',
      },
      {
        title: 'İletişim',
        route: 'Contact',
        resource: 'WEBFORM_CONTACT',
        action: 'read',
      },
      {
        title: 'Dijital Dönüş Süreleri',
        route: 'DigitalResponse',
        resource: 'WEBFORM_CONTACT',
        action: 'read',
      },
    ],
  },
  {
    title: 'Müşteri İlişkileri',
    icon: 'PhoneCallIcon',
    children: [
      {
        title: 'Başvuru Ekle',
        route: 'CrmSearch',
        resource: 'CRM_REQUESTS_ADD',
        action: 'read',
      },
      {
        title: 'Bekleyen',
        route: 'CrmPending',
        resource: 'CRM_REQUESTS_LIST',
        action: 'read',
      },
      {
        title: 'Takip Edilen',
        route: 'CrmTracking',
        resource: 'CRM_REQUESTS_LIST',
        action: 'read',
      },
      {
        title: 'Onay Bekleyen',
        route: 'CrmApproval',
        resource: 'CRM_REQUESTS_LIST',
        action: 'read',
      },
      {
        title: 'Tümü',
        route: 'CrmAll',
        resource: 'CRM_REQUESTS_LIST',
        action: 'read',
      },
      {
        title: '2.El Anketler',
        route: 'G2Surveys',
        resource: 'CRM_G2_SURVEYS_LIST',
        action: 'read',
      },
      {
        title: 'Sıfır Satış Anketler',
        route: 'SaleSurveys',
        resource: 'CRM_SALE_SURVEYS_LIST',
        action: 'read',
      },
      {
        title: 'Web İletişim Atamaları',
        route: 'ContactAssigments',
        resource: 'CRM_CONTACT_ASSIGMENT',
        action: 'read',
      },
      {
        title: 'Harita Puan Verileri',
        route: 'MapScores',
        resource: 'CRM_MAP_SCORES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Servis',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Randevular',
        route: 'Service',
        resource: 'SERVICES_LIST',
        action: 'read',
      },
      {
        title: 'İndirim Kodu Doğrulama',
        route: 'ServiceCoupon',
        resource: 'SERVICES_LIST',
        action: 'read',
      },
      {
        title: 'Müşteri SMS Onay',
        route: 'ServiceSMS',
        resource: 'SERVICES_SMS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Sigorta',
    icon: 'LifeBuoyIcon',
    children: [
      {
        title: 'Yenileme Listesi',
        children: [
          {
            title: 'Liste',
            route: 'InsuranceRenewals',
            resource: 'INSURANCE_RENEWAL_LIST',
            action: 'read',
          },
          {
            title: 'Dosya Aktarımı',
            route: 'InsuranceRenewalImport',
            resource: 'INSURANCE_RENEWAL_IMPORT',
            action: 'read',
          },
          {
            title: 'Dönemler',
            route: 'InsuranceRenewalPeridos',
            resource: 'INSURANCE_RENEWAL_IMPORT',
            action: 'read',
          },
        ],
        resource: 'INSURANCE_PUBLIC',
        action: 'read',
      },
      {
        title: 'Toplu Görüşme Notu',
        route: 'InterviewBatch',
        resource: 'INSURANCE_INTERVIEW_BATCH',
        action: 'read',
      },
    ],
  },
  {
    title: 'Pazarlama',
    icon: 'GlobeIcon',
    children: [
      {
        title: 'Dijital',
        children: [
          {
            title: 'Reklamlar',
            route: 'MarketingAds',
            resource: 'MARKETING_ADS_LIST',
            action: 'read',
          },
          {
            title: 'Reklam Formları',
            route: 'MarketingAdForms',
            resource: 'MARKETING_ADFORMS_LIST',
            action: 'read',
          },
          {
            title: 'Reklam Tipleri',
            route: 'MarketingAdTypes',
            resource: 'MARKETING_ADTYPES_LIST',
            action: 'read',
          },
          {
            title: 'Reklam Platformları',
            route: 'MarketingAdPlatforms',
            resource: 'MARKETING_ADPLATFORMS_LIST',
            action: 'read',
          },
        ],
        resource: 'MARKETING_DIGITAL',
        action: 'read',
      },
      {
        title: 'Doğum Günü SMS',
        route: 'MarketingBirthdaySms',
        resource: 'MARKETING_BIRTHDAY_SMS',
      },
      {
        title: 'Kampanya Smsleri',
        route: 'MarketingCampaignSms',
        resource: 'MARKETING_CAMPAIGN_SMS',
      },
      {
        title: 'Data Aktarım',
        route: 'MarketingBatchInterviews',
      },
    ],
  },
  {
    title: 'İnsan Kaynakları',
    icon: 'UsersIcon',
    children: [
      {
        title: 'İzin Talepleri',
        resource: 'HR_PERMIT_REQUEST_ADD',
        action: 'read',
        children: [
          {
            title: 'Gelen İzin Talepleri',
            route: 'PermitRequest',
            resource: 'HR_PERMIT_REQUEST_LIST',
            action: 'read',
          },
          {
            title: 'Onaylanan İzin Talepleri',
            route: 'PermitRequestSuccess',
            resource: 'HR_PERMIT_SUCCESS_LIST',
            action: 'read',
          },
          {
            title: 'İzin Taleplerim',
            route: 'PermitRequestList',
            resource: 'HR_PERMIT_IMREQUEST_LIST',
            action: 'read',
          },
          {
            title: 'Oluştur',
            route: 'PermitRequestAdd',
            resource: 'HR_PERMIT_REQUEST_ADD',
            action: 'read',
          },
        ],
      },
    ],
    action: 'read',
  },
  {
    title: 'Raporlar',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Sıfır Satış',
        children: [
          {
            title: 'GPR',
            route: 'ReportsSalesGPR',
            resource: 'REPORTS_SALES_GPR',
            action: 'read',
          },
          {
            title: 'Yönetim GPR',
            route: 'ReportsSalesManagerGPR',
            resource: 'REPORTS_SALES_MANAGER_GPR',
            action: 'read',
          },
          {
            title: 'Temsilciler',
            route: 'ReportsSaleUsers',
            resource: 'REPORTS_SALE_USERS',
            action: 'read',
          },
          {
            title: 'Danışman Karnesi',
            route: null,
          },
          {
            title: 'Dijital',
            route: 'DigitalReport',
            resource: 'REPORTS_SALE_DIGITAL',
            action: 'read',
          },
          {
            title: 'Stoklar',
            route: 'SalesStockReport',
            resource: 'REPORTS_SALE_STOCKS',
            action: 'read',
          },
          {
            title: 'Görüşmeler',
            route: 'ReportsSaleInterviews',
            resource: 'REPORTS_SALE_INTERVIEWS',
            action: 'read',
          },
          {
            title: 'Aksesuar Detayı',
            route: 'ReportsSaleAccessories',
            resource: 'REPORTS_SALE_ACCESSORIES',
            action: 'read',
          },
          {
            title: 'Dijital Dönüş Süreleri',
            route: 'DigitalResponseReport',
            resource: 'REPORTS_SALE_DIGITAL_RESPONSE',
            action: 'read',
          },
          {
            title: 'İl / İlçe Satışlar',
            route: 'SalesByCity',
            resource: 'REPORTS_SALE_BY_CITY',
            action: 'read',
          },
          {
            title: 'Ön Fiyat / Ekspertiz Talepleri',
            route: 'SalesExpertise',
            resource: 'REPORTS_SALE_EXPERTISE',
            action: 'read',
          },
          {
            title: 'Satış Faturaları',
            route: 'SaleInvoices',
            resource: 'REPORTS_SALE_INVOICES',
            action: 'read',
          },
          {
            title: 'Anlaşmalı Banka Krediler',
            route: 'SalesContractedCredits',
            resource: 'REPORTS_SALE_CONTRACTED_CREDITS',
            action: 'read',
          },
        ],
        resource: 'REPORTS_SALE',
        action: 'read',
      },
      {
        title: 'Servis',
        children: [
          {
            title: 'Randevular',
            route: 'ServiceTracingInterviews',
            resource: 'REPORTS_SERVICES_INTERVIEWS',
            action: 'read',
          },
          {
            title: 'Doğum Günü Sms',
            route: 'ReportsBirthdaySms',
            resource: 'REPORTS_SERVICE_BIRTHDAY_SMS',
            action: 'read',
          },
          {
            title: 'Randevu Adetleri',
            route: 'ReportsServiceTracing',
            resource: 'REPORTS_SERVICE_TRACING',
            action: 'read',
          },
          {
            title: 'Aksesuar Randevuları',
            route: 'ReportsServiceAccessories',
            resource: 'REPORTS_SERVICE_ACCESSORIES',
            action: 'read',
          },
        ],
        resource: 'REPORTS_SERVICE',
        action: 'read',
      },
      {
        title: 'Müşteri İlişkileri',
        children: [
          {
            title: 'Başvurular',
            route: 'ReportsCrmRequests',
            resource: 'REPORTS_CRM_REQUESTS',
            action: 'read',
          },
          {
            title: 'Başvuru Dönüş Süresi',
            route: 'ReportsCrmRequestDurations',
            resource: 'REPORTS_CRM_REQUEST_DURATIONS',
            action: 'read',
          },
          {
            title: 'PBGA',
            route: null,
          },
          {
            title: '2.El Anketler',
            route: 'ReportsCrmG2Surveys',
            resource: 'REPORTS_CRM_SURVEYS',
            action: 'read',
          },
          {
            title: 'Sıfır Satış Anketler',
            route: 'ReportsCrmSurveys',
            resource: 'REPORTS_CRM_SALE_SURVEYS',
            action: 'read',
          },
          {
            title: 'Fiyat Talebi Randevuları',
            route: 'TracingPriceRequests',
            resource: 'REPORTS_CRM_TRACING_PRICE_REQUESTS',
            action: 'read',
          },
          {
            title: 'Ulaşılamayan Müşteriler',
            route: 'UnreachableRequests',
            resource: 'REPORTS_CRM_UNREACHABLE_REQUESTS',
            action: 'read',
          },
          {
            title: 'Müşteri KVKK Durumları',
            route: 'CustomersKvkkReport',
            resource: 'REPORTS_CRM_CUSTOMER_KVKK',
            action: 'read',
          },
          {
            title: 'Tüm Başvurular',
            route: 'ReportsCrmRequestExcel',
            resource: 'REPORTS_CRM_REQUESTS_EXCEL',
            action: 'read',
          },
          {
            title: 'Sadakat Programı',
            route: 'ReportsLoyaltyProgram',
            resource: 'REPORTS_CRM_LOYALTY_PROGRAM',
            action: 'read',
          },
        ],
        resource: 'REPORTS_CRM',
        action: 'read',
      },
      {
        title: 'Sigorta',
        children: [
          {
            title: 'Üretim',
            route: 'ReportsInsuranceProduction',
            resource: 'REPORTS_INSURANCE_PRODUCTION',
            action: 'read',
          },
          {
            title: 'Üretim Firma',
            route: null,
          },
          {
            title: 'Sıfır Satış',
            route: 'ReportsInsuranceSales',
            resource: 'REPORTS_INSURANCE_SALES',
            action: 'read',
          },
          {
            title: 'Yenileme',
            route: 'ReportsInsuranceRenewals',
            resource: 'REPORTS_INSURANCE_RENEWALS',
            action: 'read',
          },
        ],
        resource: 'REPORTS_INSURANCE',
        action: 'read',
      },
      {
        title: 'Pazarlama',
        children: [
          {
            title: 'Dijital Reklam',
            route: 'ReportsMarketingAds',
            resource: 'REPORTS_MARKETING_DIGITAL',
            action: 'read',
          },
          {
            title: 'Otomotiv',
            route: 'ReportsMarketingAutomotive',
            resource: 'REPORTS_MARKETING_AUTOMOTIVE',
            action: 'read',
          },
          {
            title: 'Satışlar Temas Raporu',
            route: 'ReportsMarketingMeet',
            resource: 'REPORTS_MARKETING_MEET',
            action: 'read',
          },
        ],
        resource: 'REPORTS_MARKETING',
        action: 'read',
      },
      {
        title: 'CSI',
        children: [
          {
            title: 'Marka',
            route: 'ReportsCsiBrands',
            resource: 'REPORTS_CSI_BRANDS',
            action: 'read',
          },
          {
            title: 'Danışman',
            route: 'ReportsCsiUsers',
            resource: 'REPORTS_CSI_USERS',
            action: 'read',
          },
        ],
        resource: 'REPORTS_CSI',
        action: 'read',
      },
      {
        title: 'Veri Girişi',
        children: [
          {
            title: 'Servis Giriş Adetleri',
            route: 'ServiceCounters',
            resource: 'EXTERNAL_SERVICE_COUNTERS',
            action: 'read',
          },
          {
            title: 'CSI Marka',
            route: 'CsiBrands',
            resource: 'EXTERNAL_CSI',
            action: 'read',
          },
          {
            title: 'CSI Danışman',
            route: 'CsiUsers',
            resource: 'EXTERNAL_CSI',
            action: 'read',
          },
        ],
        resource: 'EXTERNAL_DATA',
        action: 'read',
      },
    ],
  },
  {
    title: 'Tanımlamalar',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Araç Markaları',
        route: 'Swapbrands',
        resource: 'DEFINES_SWAP_BRANDS',
        action: 'read',
      },
      {
        title: 'Araç Modelleri',
        route: 'SwapModels',
        resource: 'DEFINES_SWAP_MODELS',
        action: 'read',
      },
      {
        title: 'Gönye Otomotiv Markaları',
        route: 'Brands',
        resource: 'DEFINES_BRANDS',
        action: 'read',
      },
      {
        title: 'Gönye Otomotiv Modelleri',
        route: 'Models',
        resource: 'DEFINES_MODELS',
        action: 'read',
      },
      {
        title: 'Bankalar',
        route: 'Banks',
        resource: 'DEFINES_BANKS',
        action: 'read',
      },
      {
        title: 'Finansan Maliyet Oranları',
        route: 'InterestRate',
        resource: 'DEFINES_INTEREST_RATE',
        action: 'read',
      },
      {
        title: 'Yakıt Tipleri',
        route: 'Fuels',
        resource: 'DEFINES_FUELS',
        action: 'read',
      },
      {
        title: 'Vites Tipleri',
        route: 'Gears',
        resource: 'DEFINES_GEARS',
        action: 'read',
      },
      {
        title: 'Renk Seçenekleri',
        route: 'Colors',
        resource: 'DEFINES_COLORS',
        action: 'read',
      },
      /* {
        title: 'Lastik Markaları',
        route: 'TireBrands',
        resource: 'DEFINES_TIRE_BRANDS',
        action: 'read',
      },
      {
        title: 'Lastik Boyutları',
        route: 'TireSizes',
        resource: 'DEFINES_TIRE_SIZES',
        action: 'read',
      },
      {
        title: 'Lastik Durumları',
        route: 'TireStatuses',
        resource: 'DEFINES_TIRE_STATUSES',
        action: 'read',
      },
      {
        title: 'Lastik Tipleri',
        route: 'TireTypes',
        resource: 'DEFINES_TIRE_TYPES',
        action: 'read',
      }, */
      {
        title: 'Raflar',
        route: 'TireShelfs',
        resource: 'DEFINES_TIRE_SHELFS',
        action: 'read',
      },
      {
        title: 'Başvuru Konuları',
        route: 'CrmSubjects',
        resource: 'DEFINES_CRM_SUBJECTS',
        action: 'read',
      },
      {
        title: 'Başvuru Tipleri',
        route: 'CrmFeedbackTypes',
        resource: 'DEFINES_CRM_TYPES',
        action: 'read',
      },
      {
        title: 'Başvuru Durumları',
        route: 'CrmStatuses',
        resource: 'DEFINES_CRM_STATUSES',
        action: 'read',
      },
      {
        title: 'Başvuru Kaynakları',
        route: 'CrmApplySources',
        resource: 'DEFINES_CRM_APPLY_SOURCES',
        action: 'read',
      },
      {
        title: 'Önem Dereceleri',
        route: 'CrmLevels',
        resource: 'DEFINES_CRM_LEVELS',
        action: 'read',
      },
      {
        title: 'Sigorta Firmaları',
        route: 'InsuranceCompanies',
        resource: 'DEFINES_INSURANCE_COMPANIES',
        action: 'read',
      },
      {
        title: 'Şehir',
        route: 'Cities',
        resource: 'DEFINES_CITIES',
        action: 'read',
      },
      {
        title: 'İlçe',
        route: 'Districts',
        resource: 'DEFINES_DISTRICTS',
        action: 'read',
      },
      {
        title: 'Temas',
        route: 'Meets',
        resource: 'DEFINES_MEETS',
        action: 'read',
      },
      {
        title: 'Temas Grupları',
        route: 'MeetGroups',
        resource: 'DEFINES_MEET_GROUPS',
        action: 'read',
      },
      {
        title: 'Görüşme Tipleri',
        route: 'InterviewTypes',
        resource: 'DEFINES_INTERVIEW_TYPES',
        action: 'read',
      },
      {
        title: 'Servis Tipleri',
        route: 'ServiceTypes',
        resource: 'DEFINES_SERVICE_TYPES',
        action: 'read',
      },
      {
        title: 'Servis Durumları',
        route: 'ServiceStatuses',
        resource: 'DEFINES_SERVICE_STATUSES',
        action: 'read',
      },
      {
        title: 'Servis SMS Tipleri',
        route: 'ServiceSmsTypes',
        resource: 'DEFINES_SERVICE_SMS_TYPES',
        action: 'read',
      },
      {
        title: 'Departmanlar',
        route: 'Departments',
        resource: 'DEFINES_DEPARTMENTS',
        action: 'read',
      },
      {
        title: 'Meslekler',
        route: 'Jobs',
        resource: 'DEFINES_JOBS',
        action: 'read',
      },
      {
        title: 'Sektörler',
        route: 'Sectors',
        resource: 'DEFINES_SECTORS',
        action: 'read',
      },
      {
        title: 'Lokasyon',
        route: 'Locations',
        resource: 'DEFINES_LOCATIONS',
        action: 'read',
      },
      {
        title: 'Ödeme Tipleri',
        route: 'Payments',
        resource: 'DEFINES_PAYMENT_TYPES',
        action: 'read',
      },
      {
        title: 'KDV Oranları',
        route: 'KdvRates',
        resource: 'DEFINES_KDV_RATES',
        action: 'read',
      },
      {
        title: 'ÖTV Oranları',
        route: 'OtvRates',
        resource: 'DEFINES_OTV_RATES',
        action: 'read',
      },
      {
        title: 'Kullanıcı Bölümleri',
        route: 'UserSection',
        resource: 'DEFINES_USER_SECTIONS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Sistem',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'Users',
        resource: 'USERS_LIST',
        action: 'read',
      },
      {
        title: 'Yönetici Araçları',
        route: 'CustomerDeduplication',
        children: [
          {
            title: 'Müşteri Tekilleştirme / Aktarım',
            route: 'CustomerDeduplication',
            resource: 'MANAGER_CUSTOMER_DEDUPLICATION',
            action: 'read',
          },
          {
            title: 'Kullanıcı İşlemleri',
            route: 'UserOperations',
            resource: 'MANAGER_USER_OPERATIONS',
            action: 'read',
          },
        ],
        action: 'read',
      },
      {
        title: 'Kullanıcı Tipleri',
        route: 'UserTypes',
        resource: 'USER_TYPES_LIST',
        action: 'read',
      },
      {
        title: 'SMTP Ayarları',
        route: 'SMTP',
        resource: 'SMTP_CONFIG',
      },
      /*
      {
        title: 'Sistem Geri Bildirim',
        route: 'configs/feedbacks',
      },
         */
    ],
  },
]
